var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-table-simple",
    {
      staticStyle: { "max-height": "65vh", "font-size": "12px" },
      attrs: { bordered: "", responsive: "", "sticky-header": "" },
    },
    [
      _c("colgroup", [
        _c("col", { staticStyle: { width: "110px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "250px" } }),
        _c("col", { staticStyle: { width: "130px" } }),
        _c("col", { staticStyle: { width: "130px" } }),
        _c("col", { staticStyle: { width: "130px" } }),
        _c("col", { staticStyle: { width: "150px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "150px" } }),
      ]),
      _c(
        "b-thead",
        { attrs: { "head-variant": "light" } },
        [
          _c(
            "b-tr",
            [
              _c("b-th", { staticStyle: { "text-align": "center" } }, [
                _vm._v("ID"),
              ]),
              _c("b-th", { staticStyle: { "text-align": "center" } }, [
                _vm._v("제약사"),
              ]),
              _c("b-th", [_vm._v("제품명")]),
              _c("b-th", { staticStyle: { "text-align": "center" } }, [
                _vm._v("청구코드"),
              ]),
              _c("b-th", { staticStyle: { "text-align": "center" } }, [
                _vm._v("지역"),
              ]),
              _c("b-th", { staticStyle: { "text-align": "center" } }, [
                _vm._v("게시 기간"),
              ]),
              _c("b-th", { staticStyle: { "text-align": "center" } }, [
                _vm._v("진료과"),
              ]),
              _c("b-th", { staticStyle: { "text-align": "center" } }, [
                _vm._v(_vm._s(_vm.searchType === 2 ? "전체처수" : "전체수")),
              ]),
              _c("b-th", { staticStyle: { "text-align": "center" } }, [
                _vm._v(_vm._s(_vm.searchType === 2 ? "요양기관수" : "의사수")),
              ]),
              _c("b-th", { staticStyle: { "text-align": "center" } }, [
                _vm._v(
                  _vm._s(_vm.searchType === 2 ? "기등록처수" : "기등록수")
                ),
              ]),
              _c("b-th", { staticStyle: { "text-align": "center" } }, [
                _vm._v(_vm._s(_vm.searchType === 2 ? "노출처수" : "노출수")),
              ]),
              _c("b-th", { staticStyle: { "text-align": "center" } }, [
                _vm._v("클릭수"),
              ]),
              _c("b-th", { staticStyle: { "text-align": "center" } }, [
                _vm._v("클릭수"),
                _c("br"),
                _vm._v("(중복제거)"),
              ]),
              _vm.searchType === 2
                ? _c("b-th", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("등록처수"),
                    _c("br"),
                    _vm._v("(모아보기)"),
                  ])
                : _c("b-th", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("등록수"),
                    _c("br"),
                    _vm._v("(모아보기)"),
                  ]),
              _vm.searchType === 2
                ? _c("b-th", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("등록처수"),
                    _c("br"),
                    _vm._v("(기초자료)"),
                  ])
                : _c("b-th", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("등록수"),
                    _c("br"),
                    _vm._v("(기초자료)"),
                  ]),
              _c("b-th", { staticStyle: { "text-align": "center" } }, [
                _vm._v(_vm._s(_vm.searchType === 2 ? "잔여처수" : "잔여수")),
              ]),
              _c("b-th", { staticStyle: { "text-align": "center" } }, [
                _vm._v("게시 잔여기간"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-tbody",
        [
          _vm._l(_vm.data, function (cumulative) {
            return [
              _vm._l(cumulative.landingDepts, function (dept, index) {
                return [
                  _c(
                    "b-tr",
                    {
                      key:
                        "cumulative-" +
                        cumulative.regionName +
                        "-" +
                        cumulative.liveInfoId +
                        "-" +
                        index,
                    },
                    [
                      index === 0
                        ? _c(
                            "b-td",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: {
                                rowspan: cumulative.landingDepts.length,
                              },
                            },
                            [_vm._v(" " + _vm._s(cumulative.liveInfoId) + " ")]
                          )
                        : _vm._e(),
                      index === 0
                        ? _c(
                            "b-td",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: {
                                rowspan: cumulative.landingDepts.length,
                              },
                            },
                            [_vm._v(" " + _vm._s(cumulative.clientName) + " ")]
                          )
                        : _vm._e(),
                      index === 0
                        ? _c(
                            "b-td",
                            {
                              attrs: {
                                rowspan: cumulative.landingDepts.length,
                              },
                            },
                            [_vm._v(" " + _vm._s(cumulative.drugName) + " ")]
                          )
                        : _vm._e(),
                      index === 0
                        ? _c(
                            "b-td",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: {
                                rowspan: cumulative.landingDepts.length,
                              },
                            },
                            [_vm._v(" " + _vm._s(cumulative.cgCode) + " ")]
                          )
                        : _vm._e(),
                      index === 0
                        ? _c(
                            "b-td",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: {
                                rowspan: cumulative.landingDepts.length,
                              },
                            },
                            [_vm._v(" " + _vm._s(cumulative.regionName) + " ")]
                          )
                        : _vm._e(),
                      index === 0
                        ? _c(
                            "b-td",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: {
                                rowspan: cumulative.landingDepts.length,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("$dateFormatter")(
                                      cumulative.startDate,
                                      "MM월 DD일"
                                    )
                                  ) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                "~ " +
                                  _vm._s(
                                    _vm._f("$dateFormatter")(
                                      cumulative.endDate,
                                      "MM월 DD일"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(_vm._s(dept.medicalDeptName)),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              dept.wasRegCount + dept.unRegCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          _vm._s(_vm._f("$numberFormatter")(dept.uniqueCount))
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          _vm._s(_vm._f("$numberFormatter")(dept.wasRegCount))
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          _vm._s(_vm._f("$numberFormatter")(dept.unRegCount)) +
                            " "
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(dept.totalClickCount)
                          ) + " "
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(dept.groupClickCount)
                          ) + " "
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(dept.landingRegCount)
                          ) + " "
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          _vm._s(_vm._f("$numberFormatter")(dept.ysrRegCount)) +
                            " "
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              dept.unRegCount -
                                (dept.landingRegCount + dept.ysrRegCount)
                            )
                          ) + " "
                        ),
                      ]),
                      index === 0
                        ? _c(
                            "b-td",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: {
                                rowspan: cumulative.landingDepts.length,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getRemainDate(cumulative.endDateStr) +
                                      "일"
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }